<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-md-6 p-md-0">
        <div class="welcome-text">
          <h4 v-if="subject.year">
            Subjects of {{ subject.year.value }} Academic Year
          </h4>
        </div>
      </div>
      <div class="col-md-6 p-md-0 justify-content-md-end mt-2 mt-md-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item active">Subjects View</li>
        </ol>
      </div>
    </div>

    <div class="row tab-content">
      <div id="" class="tab-pane fade active show col-md-9 col-sm-12">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">Subject Details</h4>
          </div>
          <div class="card-body">
            <div class="basic-form">
              <div class="form-group row">
                <label class="col-md-12 col-form-label" style="color: rgb(120, 27, 32)">
                  <i class="custom-left-border"></i> Subject
                </label>
              </div>

              <div class="form-group row">
                <label class="col-md-2 col-form-label">Name: </label>
                <label class="col-md-10 col-form-label">
                  {{ subject.name }}
                </label>
              </div>

              <div class="form-group row">
                <label class="col-md-2 col-form-label">Level: </label>
                <label v-if="subject.level" class="col-md-10 col-form-label">
                  {{ subject.level.value }}
                </label>
              </div>

              <div class="form-group row">
                <label class="col-md-2 col-form-label">Term: </label>

                <label v-if="subject.term" class="col-md-10 col-form-label">
                  {{ subject.term.name }}
                </label>
              </div>

              <!-- Students Grades -->
              <div class="form-group row">
                <label class="col-md-12 col-form-label" style="color: rgb(120, 27, 32)">
                  <span><i class="custom-left-border"></i> Students Grades</span>
                </label>
              </div>

              <div class="table-responsive">
                <table class="table table-bordered table-striped verticle-middle table-responsive-sm">
                  <thead>
                    <tr>
                      <th scope="col">Code</th>
                      <th scope="col">Name</th>
                      <th scope="col">Class</th>
                      <th scope="col">Teachers</th>
                      <th scope="col" v-for="(item, index) in subject.grade_items" :key="index">
                        {{ item.name }} [Max: {{ item.max_grade }}]
                      </th>
                      <th>Sum </th>
                    </tr>
                  </thead>
                  <tbody v-if="subject.grades">
                    <tr v-for="grade in subject.grades" :key="grade.id">
                      <td>{{ grade.student.user.code }}</td>
                      <td>{{ grade.student.user.fullname }}</td>
                      <td>{{ grade.class.name }}</td>
                      <td>
                        <span v-for="teacher in grade.teachers" :key="teacher.id">
                          - {{ teacher.firstname }} {{ teacher.middlename }} <br>
                        </span>
                      </td>
                      <td v-for="result in grade.results" :key="result.id">
                        {{ result.grade_value }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td style="text-align: center" colspan="14">
                        No Data Available.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useSubject from "@/composables/subjects";
import { inject, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
export default {
  props: {
    id: {
      required: true,
      type: Number,
    },
  },
  setup(props) {
    const hasPermission = inject("hasPermission");
    const router = useRouter();

    const { getSubject, subject } = useSubject();

    onBeforeMount(async () => {
      //first: check the user Permission
      if (!hasPermission("view_subjects")) {
        router.push({
          name: "401",
        });
      } else {
        await getSubject(props.id).then(() => {
          subject.value.grades.forEach((grade) => {
            let sum_grade_value = grade.results.reduce((acc, item) => acc + item.grade_value, 0);
            grade.results.push({
              grade_value: sum_grade_value
            })
          });
        });
      }
    });

    return {
      subject,
    };
  },
};
</script>
